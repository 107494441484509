import {DataSheet} from "../DataSheet";

import SRA from './Skitarii-Ranger-Alpha.json'
// @ts-ignore
import marshal from '../images/marshal.png'
import CRP from './Sicarian-Ruststalker-Princeps.json'
// @ts-ignore
import rsp from "../images/rsp.png"

import CRA from './Sicarian-Ruststalker-Assassin.json'
// @ts-ignore
import inf1 from "../images/sci_inf.png"
import CIT from './Sicarian-Infiltrator-Tracker.json'
// @ts-ignore
import rs1 from "../images/rs1.png"
// @ts-ignore
import rs2 from "../images/rs2.png"
// @ts-ignore
import rs3 from "../images/rs3.png"
// @ts-ignore
import rs4 from "../images/rs4.png"


import SRM from './Skitarii-Ranger-Marksman.json'
// @ts-ignore
import sr1 from "../images/ranger1.png"
// @ts-ignore
import sr2 from "../images/ranger2.png"
// @ts-ignore
import sr3 from "../images/ranger3.png"
// @ts-ignore
import sr4 from "../images/ranger4.png"

import SRGa from './Skitarii-Ranger-Gunner_arc.json';
// @ts-ignore
import rg_a from "../images/rg_a.png"
import SRGta from './Skitarii-Ranger-Gunner_arquebus.json';
// @ts-ignore
import rg_ta from "../images/rg_ta.png"
import SRGp from './Skitarii-Ranger-Gunner_plasma.json';
// @ts-ignore
import rg_p from "../images/rg_p.png"

import SRD from './Skitarii-Ranger-Diktat.json'
// @ts-ignore
import srd from "../images/srd.png"
import SRS from './Skitarii-Ranger-Surveyor.json'
// @ts-ignore
import srs from "../images/srs.png"


import SVS from './Skitarii-Vanguard-Shorcktrooper.json'
// @ts-ignore
import vs1 from "../images/vs1.png"
// @ts-ignore
import vs2 from "../images/vs2.png"
// @ts-ignore
import vs3 from "../images/vs3.png"


import SVD from './Skitarii-Vanguard-Diktat.json'


// @ts-ignore
import vanguardDiktat from "../images/VanguardDiktat.png"
// @ts-ignore
import vanguardShocktrooper1 from "../images/VanguardShocktrooper1.png"




const hunterClade: DataSheet[] = [
//leaders
    {...SRA,image:marshal},
    {...CRP,image:rsp},
//sicarian
    {...CIT, image: inf1},

    {...CRA,image:rs1},
    {...CRA,image:rs2},
    {...CRA,image:rs3},
    {...CRA,image:rs4},

//rangers
    {...SRM, image:sr1},
    {...SRM, image:sr2},
    {...SRM, image:sr3},
    {...SRM, image:sr4},

    //-special
    {...SRD,image:srd},
    {...SRS,image:srs},

    //-gunners
    {...SRGa,image:rg_a},
    {...SRGta,image:rg_ta},
    {...SRGp,image:rg_p},


//vanguards
    {...SVS, image: vs1},
    {...SVS, image: vs2},
    {...SVS, image: vs3},

//-special
    {...SVD, image: vanguardDiktat}
]
export default hunterClade;
