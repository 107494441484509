import React from "react";


// @ts-ignore
import triangle from "./measurements/f1.png"
// @ts-ignore
import circle from "./measurements/f2.png"
// @ts-ignore
import square from "./measurements/f3.png"
// @ts-ignore
import pentagon from "./measurements/f6.png"


export const replaceMeasurements = (str: string, fontSize:number) => {
    // return str
    let inbetween = []
    let txt = str.split('')
    // console.log(txt)
    // 8 char long
    let imgStyle = {
        height: `${fontSize}pt`,
        width: `${fontSize}pt`,
        display: 'inline-block',
        verticalAlign: 'middle',
    }

    let circlestr = '&#11044;';
    let circlejsx = <img src={circle} style={imgStyle}/>
    let squarestr = '&#11035;'
    let squarejsx = <img src={square} style={imgStyle}/>
    let pentstr = '&#11039;'
    let pentjsx = <img src={pentagon} style={imgStyle}/>

    for (let i = 0; i < txt.length; i++) {
        if (txt[i] === '&') {
            if ((i + 7) < txt.length) {
                let temp = "".concat(txt[i], txt[i + 1], txt[i + 2], txt[i + 3], txt[i + 4], txt[i + 5], txt[i + 6], txt[i + 7])

                switch (temp) {
                    case circlestr:
                        // console.log("circle");
                        // inbetween.push('CIRC!')
                        inbetween.push(circlejsx)
                        i = i + 7;
                        break;
                    case squarestr:
                        // console.log('square');
                        // inbetween.push('SQR!')
                        inbetween.push(squarejsx)
                        i = i + 7;
                        break;
                    case pentstr:
                        // console.log('pent');
                        // inbetween.push('PENT!')
                        inbetween.push(pentjsx)
                        i = i + 7;
                        break;
                    default:
                        // console.log('flush');
                        inbetween.push(txt[i])
                        break;
                }
            } else {
                // console.log('not enough string')
                inbetween.push(txt[i])
            }
        } else {
            // console.log('next')
            inbetween.push(txt[i])
        }
    }

    // console.log('original:',txt.toString())
    // console.log('new:',inbetween.toString())

    return (<>{inbetween}</>)
}