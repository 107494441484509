import styled from "@emotion/styled";

export const BottomBox = styled('div')(() => ({
    height: "max-content",
    width: `${144 - 6 - 6}pt`,
    boxSizing: 'border-box',

    margin: '0',
    padding: '0',

    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    alignContent: 'flex-end',

    alignSelf: 'center'

    // border: '1pt solid blue'

}))