import React, {useState} from 'react'
import {EntryType} from "./DataPlate";
import {DataSheet} from "./DataSheet";
import hunterClade from "./hunterClade/hunterClade";
import DataPlateBigImage from "./DataPlateBigImage";
import ReverseSide from "./ReverseSide";


const collectBackSides = () => {
    let cards = []
    for (let i = 0; i < 20; i++) {
        cards.push(
            <ReverseSide
                name={'MyNameHere'}
                style={{display: 'grid-item'}}
            />
        )
    }
    return cards;
}

export default function App(): JSX.Element {

    const [face, setFace] = useState(true)

    const Justicar: DataSheet = {
        name: "Justicar",
        specialism: [],
        stat: {M: '3o', APL: '3', GA: '1', DF: '3', SV: '3+', W: '12'},
        weapons: {
            'Storm Bolter': {A: '4', S: '3+', D: '4/6', SR: 'Relentless', C: "-"},
            'x Nemesis Force Weapon': {A: '5', S: '2+', D: '4/6', SR: 'Lethal 5+', C: '-'},
            'x Nemesis Warding Stave': {A: '5', S: '2+', D: '4/5', SR: '-', C: 'Stun'},
        },
        abilities: {},
        actions: {
            'Armored Resilience': 'Improve Save by 1',
            'Hammerhead': 'Each Fight, do 1 additional dmg',
            'Astral Aim': 'Ranged Weapons gain No Cover',
        },
        keywords: ['GREY KNIGHT', 'IMPERIUM', 'SANCTIC ASTARTES', '<BROTHERHOOD>', 'PSYKER', 'LEADER', 'JUSTICAR'],
    }

    const JusticarET: EntryType[] = [
        // stat lines
        {
            key: 'M', value: '3o'
        },
        {
            key: 'APL', value: '3'
        },
        {
            key: 'GA', value: '1'
        },
        {
            key: 'DF', value: '3'
        },
        {
            key: 'SV', value: '3+'
        },
        {
            key: 'W', value: '12'
        },
        // weapons
        // Name A bs/ws D Sr !
        {
            key: 'Storm Bolter', value: '4 3+ 4/6 Relentless -'
        },
        {
            key: 'x Nemesis Force Weapon', value: '5 2+ 4/6 Lethal 5+ -'
        },
        {
            key: 'x Nemesis Warding Stave', value: '5 2+ 4/5 - Stun'
        },
        //Abilities
        {
            key: 'null', value: 'null'
        },
        //unique actions
        {
            key: 'Armored Resilience', value: 'Improve Save by 1'
        },
        {
            key: 'Hammerhead', value: 'Each Fight, do 1 additional dmg'
        },
        {
            key: 'Astral Aim', value: 'Ranged Weapons gain No Cover'
        },
        // keywords
        {
            key: 'Keywords', value: 'GREY KNIGHT, IMPERIUM, SANCTIC ASTARTES, <BROTHERHOOD>, PSYKER, LEADER, JUSTICAR'
        },

    ]

    return (<>

        {
            (false) ? <>
                    <DataPlateBigImage style={{display: 'grid-item'}}
                                       entry={hunterClade[0]}
                    />

                </> :
                <>
                    <main>

                        {/*Kill Team WOoo!*/}
                        {/*<DataPlate*/}
                        {/*    title={"Justicar"}*/}
                        {/*    entries={JusticarET}*/}
                        {/*/>*/}
                        {/*<hr/>*/}
                        {/*<DataPlate2*/}
                        {/*    entry={Justicar}*/}
                        {/*/>*/}
                        {/*<hr/>*/}

                        <div style={{
                            margin: '5px',
                            display: 'grid',
                            gap: '25px',
                            // gridAutoFlow: 'column', //to fill columns before rows
                            // direction:'rtl', //to fill right to left
                            width: 'max-content',
                            gridTemplateColumns: 'auto auto auto auto',
                            gridTemplateRows: "auto auto auto auto auto",
                        }}>

                            {face ?
                                <>
                                    {
                                        hunterClade.map(entry =>
                                            <DataPlateBigImage style={{display: 'grid-item'}}
                                                               entry={entry}
                                            />
                                        )
                                    }
                                </>
                                :
                                <>                             {
                                    collectBackSides()
                                }
                                </>
                            }

                        </div>

                        {/*<button onClick={composer}>convert</button>*/}

                        <button
                            onClick={() => {
                                setFace(!face)
                            }}
                            style={{width: '3rem', height: '2rem'}}
                        >
                            flip
                        </button>

                    </main>
                    {/*<footer style={{*/}
                    {/*    backgroundColor: palette.Complementary.secondary*/}
                    {/*}}>*/}
                    {/*    <div style={{*/}
                    {/*        color: palette.Complementary.primary,*/}
                    {/*        alignSelf: "start",*/}
                    {/*        margin: "0 auto 0 0",*/}
                    {/*        width: 'max-content'*/}
                    {/*    }}>*/}
                    {/*        Author: Joshua Schoonover*/}
                    {/*    </div>*/}
                    {/*</footer>*/}


                </>
        }

    </>)
}


//
//
//
//
// list = [b1 ,i1 ,b2 ,b3 ,i2 ,i3 , b4]
//





