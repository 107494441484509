import React from 'react';
import styled from "@emotion/styled";
import {DataSheet} from "./DataSheet";
import {TableColumns} from "ts-jsx-table";

// @ts-ignore
import spCombat from "./specialisms/Specialism_Combat.png"
// @ts-ignore
import spMarks from "./specialisms/Specialism_Marksman.png"
// @ts-ignore
import spScout from "./specialisms/Specialism_Scout.png"
// @ts-ignore
import spStaun from "./specialisms/Specialism_Staunch.png"
import {Frame} from "./Styles/BigImageDataPlate/Frame";
import {shadow} from "./Styles/TextShadow";
import {TopBox} from "./Styles/BigImageDataPlate/TopBox";
import {BottomBox} from "./Styles/BigImageDataPlate/BottomBox";
import {Item} from "./Styles/Item";
import {Corners} from "./Styles/Corners";
import {WTD} from "./Styles/BigImageDataPlate/WTD";
import {replaceMeasurements} from "./ReplaceMeasurements";
import {SpecailistIcon} from "./Styles/BigImageDataPlate/SpecailistIcon";
import {Bs} from "./Bs";
import {longTextStyles} from "./Styles/longTextStyles";


const orintation: "hor" | "vert" = "hor";
const cardSize = {length: '252pt', height: '144pt'};
const fontSize: number = 6 //10//pt
const bordercolor = 'silver'
const fontColor = '#eeeeee'
const backgroundColor = 'gray'// 'lightGreen';
const specIconFill = fontColor//'white'


const halo = shadow(1, "black")


//
// export type EntryType = {
//     unit: DataSheet
// }


const specialOutline = {border: `${fontColor} solid 1pt`, padding: '1pt'}

const WeaponColumns: TableColumns<DataSheet["weapons"]> = [
    {
        headerTitle: "Name",
        cellRender: () => <>namehere</>
    },
    {headerTitle: "A", attribute: 'A'},
    {headerTitle: "S", attribute: 'S'},
    {headerTitle: "D", attribute: 'D'},
    {headerTitle: "SR", attribute: 'SR'},
    {headerTitle: "C", attribute: 'C'},
]


const WeaponIcon = styled(WTD)(() => ({
    // margin: "0",
    height: `${fontSize}pt`,
    width: `${fontSize}pt`,
    textAlign: 'center',

    box: 'border-box',
    textShadow: 'none',
    color: 'black',

    border: `1pt solid ${specIconFill}`,
    borderRadius: '100%',
    backgroundColor: specIconFill


}))

type DataPlateProps = {
    title?: string,
    entry: DataSheet,
    style?: {},
}

export default function DataPlateBigImage(dataPlateProps: DataPlateProps) {
    const {title, entry, style} = dataPlateProps


    // 1 inch / 72 = pts
    // buissness card = 3.5" x 2.0"
    // 252 x 144 pts

    /*
            <Entry>
                <Key>key</Key><Value>value</Value>
            </Entry>
     */

    const getDecodedString = (str): string => {
        const txt = document.createElement('textarea');
        txt.innerHTML = str;
        return txt.value;
        // return str
    };
    let bgImage
    if (entry.image) {
        bgImage = {
            backgroundImage: `url("${entry.image}")`,//`url("./images/${entry.image}.jpg")`,
            backgroundRepeat: 'no-repeat',
            // backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            backgroundBlendMode: 'soft-light',//'hard-light', // color-burn
            backgroundPosition: "center",
        }
    }

    return (<Frame
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        halo={halo}
        fontSize={fontSize}
        bordercolor={bordercolor}

        style={{
            ...style,
            ...bgImage
        }}
    >

        <TopBox>

            <Item fontSize={fontSize} fontColor={fontColor} style={{
                display: 'flex',
                flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", minWidth: 'inherit'
            }}>
                <div style={{width: 'min-content'}}>
                    {entry.specialism.includes('combat') &&
                    <SpecailistIcon alt="sc" src={spCombat} fontSize={fontSize} specIconFill={specIconFill}
                                    style={{}}/>}
                    {entry.specialism.includes('staunch') &&
                    <SpecailistIcon alt="sc" src={spStaun} fontSize={fontSize} specIconFill={specIconFill} style={{}}/>}
                </div>
                <div style={{fontSize: `${fontSize * 1.25}pt`}}><b>{entry.name}</b></div>
                <div style={{width: 'min-content'}}>
                    {entry.specialism.includes('marksman') &&
                    <SpecailistIcon alt="sc" src={spMarks} fontSize={fontSize} specIconFill={specIconFill} style={{}}/>}
                    {entry.specialism.includes('scout') &&
                    <SpecailistIcon alt="sc" src={spScout} fontSize={fontSize} specIconFill={specIconFill} style={{}}/>}
                </div>
            </Item>

            {/*<Item>*/}
            {/*    t1<Bs/>t2<Bs/>t3<Bs/>t4*/}
            {/*</Item>*/}
            {/*<Item style={{fontSize: `${fontSize * 1.25}pt`}}>*/}
            {/*    {entry.name}*/}
            {/*</Item>*/}
            <Item fontSize={fontSize} fontColor={fontColor} style={{
                ...specialOutline
            }}>
                M:{replaceMeasurements(entry.stat.M, fontSize)}<Bs/>
                APL:{entry.stat.APL}<Bs/>
                GA:{entry.stat.GA}<Bs/>
                DF:{entry.stat.DF}<Bs/>
                SV:{entry.stat.SV}<Bs/>
                W:{entry.stat.W}
            </Item>


            {Object.keys(entry.weapons).map((w, i) =>
                <Item fontSize={fontSize} fontColor={fontColor} style={{
                    // border: "1pt solid purple",
                    margin: "1pt 0 1pt 0", textAlign: "start",
                    flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", minWidth: 'inherit',
                    display: 'flex',
                    alignContent: "stretch",
                }}>
                    <WTD>{getDecodedString(w).slice(0, 1).trim()}
                    </WTD>
                    <WTD>{getDecodedString(w).slice(1).trim()}
                    </WTD>
                    <WTD>{entry.weapons[w].A}
                    </WTD>
                    <WTD>{entry.weapons[w].S}
                    </WTD>
                    <WTD>{entry.weapons[w].D}
                    </WTD>
                    <WTD>
                        {/*{getDecodedString(entry.weapons[w].SR)}*/}
                        {replaceMeasurements(entry.weapons[w].SR, fontSize)}
                    </WTD>
                    <WTD>{entry.weapons[w].C}
                    </WTD>
                </Item>)}


        </TopBox>


        <BottomBox>
            {Object.keys(entry.abilities).length > 0 ?
                <Item fontSize={fontSize} fontColor={fontColor} style={{}}>
                    <b>Abilities:</b>{Object.keys(entry.abilities).map(ab => <p
                    style={longTextStyles}>&sdot;{ab}:
                    {/*{getDecodedString(entry.abilities[ab])} </p>)}*/}
                    {replaceMeasurements(entry.abilities[ab], fontSize)}</p>)}
                </Item>
                : <></>}
            {Object.keys(entry.actions).length > 0 ?
                <Item fontSize={fontSize} fontColor={fontColor} style={{}}>
                    <b>Actions:</b>{Object.keys(entry.actions).map(ac => {
                    return <p
                        style={longTextStyles}>&sdot;{ac} :
                        {/*{getDecodedString(entry.actions[ac])} </p>)}*/}
                        {replaceMeasurements(entry.actions[ac], fontSize)} </p>;
                })}
                </Item>
                : <></>}
            {/*</div>*/}
            {/*</div>*/}

            <Item fontSize={fontSize} fontColor={fontColor} style={{...specialOutline}}>
                {entry.keywords.map(k => k + ", ")}
            </Item>
        </BottomBox>


        <Corners backgroundColor={backgroundColor} bordercolor={bordercolor}/>
    </Frame>)
}
