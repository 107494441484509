import styled from "@emotion/styled";
import React from "react";

export const SpecailistIcon =
    ({alt, src, fontSize, specIconFill, style})=>
        <img alt={alt} src={src}
            style={{
                ...style,
                // styled('img')(() => ({
                margin: "0", height: `${fontSize}pt`,
                border: `1pt solid ${specIconFill}`,
                borderRadius: '100%',
                backgroundColor: specIconFill
            }}/>
// }))