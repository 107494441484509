import React from "react";

export const Corners = ({bordercolor, backgroundColor}) => {
    const border = `${bordercolor} solid 3pt`
    const offset = '-2pt'
    const inside = {
        width: '2pt', height: '2pt', backgroundColor: backgroundColor,
    }
    return (<>
        <div style={{
            zIndex: 5,
            position: 'absolute',
            borderRadius: '0 100% 100% 100%',
            left: offset,
            top: offset,
            border: border,
            ...inside,
        }}/>
        <div style={{
            zIndex: 5,
            position: 'absolute',
            borderRadius: ' 100% 0 100% 100%',
            right: offset,
            top: offset,
            border: border,
            ...inside,
        }}/>
        <div style={{
            zIndex: 5,
            position: 'absolute',
            borderRadius: '100% 100% 100% 0',
            left: offset,
            bottom: offset,
            border: border,
            ...inside,
        }}/>
        <div style={{
            zIndex: 5,
            position: 'absolute',
            borderRadius: '100% 100% 0 100%',
            right: '-2pt',
            bottom: '-2pt',
            border: border,
            ...inside,
        }}/>
    </>)
}