import styled from "@emotion/styled";

export const WTD = styled('p')(() => ({
    width: 'auto',
    height: 'min-content',

    boxSizing: 'inherit',
    margin: '0 2pt 0 2pt',

    whiteSpace: 'break-spaces',
    // wordWrap:'break-word',
    textAlign: 'center',
    alignContent: 'baseline',

    // border: '1pt solid antiquewhite',

}))