import React from 'react'
import {shadow} from "./Styles/TextShadow";
import {Frame} from "./Styles/BigImageDataPlate/Frame";
import {Corners} from "./Styles/Corners";
import {TopBox} from "./Styles/BigImageDataPlate/TopBox";
import {BottomBox} from "./Styles/BigImageDataPlate/BottomBox";
import {longTextStyles} from "./Styles/longTextStyles";
import {Bs} from "./Bs";


const orintation: "hor" | "vert" = "hor";
const cardSize = {length: '252pt', height: '144pt'};
const fontSize: number = 6 //10//pt
const bordercolor = 'silver'
const fontColor = '#eeeeee'
const backgroundColor = 'white'//'gray'// 'lightGreen';
const specIconFill = fontColor//'white'

const halo = shadow(1, "black")

const lines = (count: number) => <> {'_'.repeat(count)} </>


type ReverseSideProps = {
    name: string,
    style?: {},
}

export default function ReverseSide(reverseSideProps: ReverseSideProps) {
    const {name, style} = reverseSideProps

    return (<Frame
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        halo={halo}
        fontSize={fontSize}
        bordercolor={bordercolor}

        style={{
            ...style,
        }}>
        <TopBox>
            <br/>
            <div style={{fontSize: `${fontSize * 1.25}pt`}}><b>
                {lines(20)}
            </b></div>
            <br/>
            XP {lines(10)}
            Rank <Bs/><Bs/><Bs/><Bs/><Bs/><Bs/><Bs/><Bs/><Bs/><Bs/> <br/>
            <br/> <br/> <br/>
            Equipement: {lines(20)}
            <br/> <br/>
            Honors: <br/>
            {lines(30)}<br/>
            {lines(30)}<br/>
            {lines(30)}<br/>
            {lines(30)}<br/>
            <br/>
            Wounds: <br/>
            {lines(30)}<br/>
            {lines(30)}<br/>


        </TopBox>
        <BottomBox>
            Tallies: <br/> <br/>
            TacOp: {lines(5)} <Bs/>
            Prmy: {lines(5)} <Bs/>
            Kill: {lines(5)}
            <br/> <br/> <br/>

            Notes <br/>
            <p style={{...longTextStyles, wordBreak: 'break-all'}}> {lines(145)} </p>

        </BottomBox>

        <Corners backgroundColor={backgroundColor} bordercolor={bordercolor}/>
    </Frame>)


}