export const Frame = ({
                          backgroundColor,
                          fontColor,
                          halo,
                          fontSize,
                          bordercolor,
                          children,
                          style
                      }) =>
    //styled('div')(() => ({
    <div style={{
        ...style,

        position: 'relative',

        backgroundColor: backgroundColor,
        color: fontColor,
        ...halo,
        textTransform: 'uppercase',
        fontSize: `${fontSize}pt`,


        // width: orintation === 'hor' ? '252pt' : '144pt',
        // height: orintation === 'hor' ? '144pt' : '252pt',

        height: '252pt',
        width: '144pt',

        border: `${bordercolor} solid 3pt`,
        boxSizing: 'border-box',
        padding: '2pt',//'3pt'
        // overflow: 'clip',
        //
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'stretch',//'flex-start',
        alignContent: 'stretch',// 'flex-start'
        justifyContent: 'space-between'
        // display: 'grid',
        // gridTemplateAreas:
        //     `"lt ls name name rs rt"
        //      "lt ls blank blank rs rt"
        //      "weaps weaps weaps weaps weaps weaps"
        //      "ab ab ab ac ac ac"
        //      "kw kw kw kw kw kw"`,
        // "weaps weaps weaps weaps weaps weaps weaps weaps"
        // "weaps weaps weaps weaps weaps weaps weaps weaps"

        // gridTemplateColumns: ' 0.3fr 0.55fr  2fr 2fr  0.55fr 0.3fr',
        // gridTemplateRows:  `1fr 2fr 1fr 6fr auto`//`1fr 4fr 2fr 3fr auto`//`repeat(13,1fr)`// ${fontSize+2}pt)`,

// }))
    }}>{children}</div>
