import React from 'react'
import styled from "@emotion/styled";

export const Item =({fontSize, fontColor, children, style})=>
    <div style={{
            ...style,
        // = styled('div')(() => ({
        fontSize: `${fontSize}pt`,
        fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
        textTransform: 'inherit',

        boxSizing: 'inherit',
        // overflow:'hidden',
        margin: '1pt auto 1pt auto',

        maxWidth: 'max-content',
        // maxHeight: '12pt',

        color: `${fontColor}`,


        // border: '1pt solid green'

// }))
    }}>{children}</div>